/* 代码字体 */
@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-ExtraLight.woff2) format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-ExtraLight-Italic.woff2) format("woff2");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-Light.woff2) format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-Light-Italic.woff2) format("woff2");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-SemiLight.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-SemiLight-Italic.woff2) format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMonoItalic;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-Italic.woff2) format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-Medium.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-Medium-Italic.woff2) format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-Bold.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-Bold-Italic.woff2) format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-ExtraBold.woff2) format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono;
  src: url(https://cdn.jsdelivr.net/gh/inkss/common@1/fonts/jetbrains/JetBrainsMono-ExtraBold-Italic.woff2) format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
